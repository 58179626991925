const LegalNotice = () => {
    return (
      <>
      <div className="max-w-3xl px-4 pt-6 lg:pt-10 pb-12 sm:px-6 lg:px-8 mx-auto">
        <div className="max-w-2xl">
          
          <div className="space-y-5 md:space-y-8">
            <div className="space-y-3">
              <h2 className="text-2xl font-bold md:text-3xl dark:text-white">Legal Notice</h2>
                  </div>
            <p className="text-lg text-gray-800 dark:text-gray-200">Welcome to HasPostCredits! Our mission is to provide avid movie fans with information on whether movies contain post-credits scenes. This service is designed to enhance your movie-watching experience by informing you of additional content that may be available after the main film ends.</p>
            <div className="space-y-3">
              <h3 className="text-2xl font-semibold dark:text-white">Source Acknowledgment</h3>
              <p className="text-lg text-gray-800 dark:text-gray-200">In delivering this service, we rely on a variety of sources to compile accurate and up-to-date information. Our primary source is The Movie Database (TMDb), which offers comprehensive data on films. Additionally, we utilize a range of other carefully selected and reputable sources to ensure the reliability of our content.</p>
            </div>
            <div className="space-y-3">
              <h3 className="text-2xl font-semibold dark:text-white">Licensing and Use of Information</h3>
              <p className="text-lg text-gray-800 dark:text-gray-200">Our website and its contents are licensed under the Creative Commons Attribution-ShareAlike 4.0 International License (CC BY-SA 4.0). This means you are free to share and adapt our information, provided you give appropriate credit and distribute your contributions under the same license as the original.</p>
            </div>
            <div className="space-y-3">
              <h3 className="text-2xl font-semibold dark:text-white">Trademark and Copyright Notice</h3>
              <p className="text-lg text-gray-800 dark:text-gray-200">HasPostCredits may reference various trademarks and copyrighted names of movies, characters, and other related media. These trademarks and copyrights are the property of their respective owners, and our use of them does not imply ownership or affiliation with these entities. We respect the rights of these trademark and copyright holders and are careful to use such references in a fair and legal context.</p>
            </div>
            <div className="space-y-3">
              <h3 className="text-2xl font-semibold dark:text-white">User Responsibility</h3>
              <p className="text-lg text-gray-800 dark:text-gray-200">Users of HasPostCredits are encouraged to use the information provided responsibly. We strive to maintain the accuracy of our data, but we also advise users to consider the potential for occasional discrepancies or updates that may occur in our source material.</p>
            </div>
            <div className="space-y-3">
              <h3 className="text-2xl font-semibold dark:text-white">Changes to This Notice</h3>
              <p className="text-lg text-gray-800 dark:text-gray-200">We reserve the right to make changes to this legal notice at any time. Such changes will be posted on our website and will be effective immediately upon posting.</p>
            </div>
            <p className="text-lg text-gray-800 dark:text-gray-200">You can contact us through email if you have any doubt or concern (<a href="mailto:hello@haspostcredits.com">hello@haspostcredits.com</a>).</p>
            <p className="text-lg text-gray-800 dark:text-gray-200">Thank you for using HasPostCredits, and enjoy your movie experience!</p>
          </div>
        </div>
      </div>
      
      </>
    );
};

export default LegalNotice;
