const PrivacyPolicy = () => {
    return (
      <>
      <div className="max-w-3xl px-4 pt-6 lg:pt-10 pb-12 sm:px-6 lg:px-8 mx-auto">
        <div className="max-w-2xl">
          
          <div className="space-y-5 md:space-y-8">
            <div className="space-y-3">
              <h2 className="text-2xl font-bold md:text-3xl dark:text-white">Privacy Policy</h2>
            </div>
            <p className="text-lg text-gray-800 dark:text-gray-200">Your privacy is important to us. It is HasPostCredits' policy to respect your privacy regarding any information we may collect while operating our website. This Privacy Policy applies to <a href="https://haspostcredits.com">haspostcredits.com</a> (hereinafter, "us", "we", "poscreditos.es", or "haspostcredits.com"). We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website.</p>
            <div className="space-y-3">
              <h3 className="text-2xl font-semibold dark:text-white">Use of Cookies</h3>
              <p className="text-lg text-gray-800 dark:text-gray-200">Our website uses "cookies" to collect information and to improve our Service. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use some portions of our Service.</p>
            </div>
            <div className="space-y-3">
              <h3 className="text-2xl font-semibold dark:text-white">Google Analytics Cookies</h3>
              <p className="text-lg text-gray-800 dark:text-gray-200">We use Google Analytics, a service provided by Google, Inc., to gather and analyze data about the use of our website. Google Analytics uses its own cookies, which are text files placed on your computer, to help the website analyze how users use the site. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States.</p>
              <p className="text-lg text-gray-800 dark:text-gray-200">Google will use this information for the purpose of evaluating your use of our website, compiling reports on website activity for website operators, and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google's behalf.</p>
            </div>
            <div className="space-y-3">
              <h3 className="text-2xl font-semibold dark:text-white">Opting Out</h3>
              <p className="text-lg text-gray-800 dark:text-gray-200">You may refuse the use of cookies by selecting the appropriate settings on your browser. However, please note that if you do this, you may not be able to use the full functionality of this website. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above.</p>
            </div>
            <div className="space-y-3">
              <h3 className="text-2xl font-semibold dark:text-white">More Information</h3>
              <p className="text-lg text-gray-800 dark:text-gray-200">For more information on Google Analytics cookies, see the official Google Analytics page.</p>
            </div>
            <div className="space-y-3">
              <h3 className="text-2xl font-semibold dark:text-white">Changes to This Privacy Policy</h3>
              <p className="text-lg text-gray-800 dark:text-gray-200">We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. These changes are effective immediately, after they are posted on this page.</p>
            </div>
            <p className="text-lg text-gray-800 dark:text-gray-200">If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us (<a href="mailto:hello@haspostcredits.com">hello@haspostcredits.com</a>).</p>
          </div>
        </div>
      </div>
      
      </>
    );
};

export default PrivacyPolicy;
