import {useTranslation} from "react-i18next";

const Footer = () => {
  const {t} = useTranslation("common");
    return (
<footer class="w-full max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 mx-auto">
  <div class="text-center">
    <div>
      <a class="flex-none text-xl font-semibold text-black dark:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#" aria-label="Brand">{t('app.title')}</a>
    </div>

    <div class="mt-3">
      <p class="text-gray-500">{t('footer.makewith')} &hearts; {t('footer.for')}</p>
      <p class="text-gray-500">{t('footer.license')} <a class="font-semibold text-blue-600 hover:text-blue-700 dark:text-blue-500 dark:hover:text-blue-400" href="https://creativecommons.org/licenses/by-sa/4.0/" target='_blank'> CC BY-SA 4.0</a>.</p>
    </div>
    <div class="mt-3">
      <p class="text-gray-500"><a class="font-semibold text-blue-600 hover:text-blue-700 dark:text-blue-500 dark:hover:text-blue-400" href="mailto:hello@haspostcredits.com" target='_blank'>{t('footer.contact')}</a> | <a class="font-semibold text-blue-600 hover:text-blue-700 dark:text-blue-500 dark:hover:text-blue-400" href="/legal" target='_blank'>{t('footer.legalnotice')}</a></p>
    </div>
  </div>
</footer>
    );
};

export default Footer;
