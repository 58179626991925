import {useTranslation} from "react-i18next";

const NotAvailable = () => {
    const {t} = useTranslation("common");
    return (
        <>
            <div class="bg-blue-100 border border-blue-200 text-gray-800 rounded-lg p-4 dark:bg-blue-800/10 dark:border-blue-900 dark:text-white" role="alert">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <svg class="flex-shrink-0 h-4 w-4 mt-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10" /><path d="M12 16v-4" /><path d="M12 8h.01" /></svg>
                    </div>
                    <div class="ms-3">
                        <h3 class="font-semibold">
                        {t('notavailable.title')}
                        </h3>
                        <div class="mt-2 text-sm text-gray-600 dark:text-gray-400">
                        {t('notavailable.subtitle')}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NotAvailable;
