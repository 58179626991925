import React, { useState } from 'react';
import {useTranslation} from "react-i18next";

interface SpoilerProps {
  description: string;
}

const Spoiler: React.FC<SpoilerProps> = ({ description }) => {
  const [isOpen, setIsOpen] = useState(false);
  const {t} = useTranslation("common");


  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <button
        type="button"
        className="py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
        onClick={toggleCollapse}
      >
        {t('movie.spoiler.title')}
        <svg
          className={`w-4 h-4 ${isOpen ? "rotate-180" : ""}`}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="m6 9 6 6 6-6" />
        </svg>
      </button>
      <div
        className={`hs-collapse ${isOpen ? "block" : "hidden"} w-full overflow-hidden transition-[height] duration-300`}
        aria-labelledby="hs-basic-collapse"
      >
        <div className="mt-5 bg-white rounded-lg py-3 px-4 dark:bg-slate-900">
          <p className="text-gray-500 dark:text-gray-400">{description}</p>
        </div>
      </div>
    </>
  );
};

export default Spoiler;
