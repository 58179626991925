import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import Navbar from './components/Navbar';
import HomePage from './components/HomePage';
import Movie from './components/Movie';
import Footer from './components/Footer';
import LegalNotice from './components/LegalNotice';
import BetaAlert from './components/BetaAlert';
import PrivacyPolicy from './components/PrivacyPolicy';

function App() {
  
  const location = useLocation();

  useEffect(() => {
    require('preline/preline');
  }, []);

  useEffect(() => {
    // @ts-ignore
    HSStaticMethods.autoInit();
  }, [location.pathname]);

  return (
    <>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/movie/:id" element={<Movie />} />
          <Route path="/legal" element={<LegalNotice />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
    </>
  );
}

export default App;
