import React from 'react';

interface MovieGenreProps {
  genre: string;
}

const Spoiler: React.FC<MovieGenreProps> = ({ genre }) => {
  return (
    <>
    <span className="items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-800/30 dark:text-blue-500" style={{ whiteSpace: 'nowrap' }}>{ genre }</span>
    </>
  );
};

export default Spoiler;
