import { useState, useEffect } from 'react';
import NotAvailable from './NotAvailable';
import {useTranslation} from "react-i18next";

interface MovieGenreProps {
  id: number;
}

type Cast = {
  id: number;
  cast: CastItem[];
};

type CastItem = {
  adult: boolean;
  gender: number;
  id: number;
  known_for_department: string;
  name: string;
  original_name: string;
  popularity: number;
  profile_path: string;
  cast_id: number;
  character: string;
  credit_id: string;
  order: number;
};

const Cast: React.FC<MovieGenreProps> = ({ id }) => {
  const [cast, setCast] = useState<Cast | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const {t} = useTranslation("common");

  const domain = window.location.hostname;
      let lng = 'en-US';

      if (domain === 'poscreditos.es') {
        lng = 'es-ES';
      }else{
        lng = 'en-US';
      }


  useEffect(() => {
    const fetchCast = async () => {
      setIsLoading(true);
      setError(null);
      let url;
      const apiUrl = process.env.REACT_APP_API_BASE_URL;
      if (!apiUrl) {
        throw new Error('API URL not found');
      }
      url = `${apiUrl}/api/${lng}/cast/${id}`;
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Something went wrong');
        }
        const data = await response.json();
        setCast(data);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCast();
  }, [id]);

  if (isLoading) {
    return (<div className="flex justify-center items-center h-[300px]"> {/* Adjust height as needed */}
      <div className="animate-spin inline-block w-8 h-8 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500" role="status" aria-label="loading">
        <span className="sr-only">Loading...</span>
      </div>
    </div>)
  }

  if (error) {
    return (
      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div className="bg-red-50 border-s-4 border-red-500 p-4 dark:bg-red-800/30" role="alert">
          <div className="flex">
            <div className="flex-shrink-0">
              <span className="inline-flex justify-center items-center w-8 h-8 rounded-full border-4 border-red-100 bg-red-200 text-red-800 dark:border-red-900 dark:bg-red-800 dark:text-red-400">
                <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
              </span>
            </div>
            <div className="ms-3">
              <h3 className="text-gray-800 font-semibold dark:text-white">
              {t('cast.error.title')}
              </h3>
              <p className="text-sm text-gray-700 dark:text-gray-400">
              {t('cast.error.subtitle')}
              </p>
            </div>
          </div>
        </div>
      </div>)
  }

  return (
<>
  <div className="group flex flex-col bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-slate-900 dark:border-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 mt-10">
    <div className="p-4 md:p-5">
      <div className="flex">
        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
          <div className="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
            <h2 className="text-2xl font-bold md:text-4xl md:leading-tight dark:text-white">{t('cast.title')}</h2>
            <p className="mt-1 text-gray-600 dark:text-gray-400">{t('cast.subtitle')}</p>
          </div>

          {cast?.cast && cast.cast.length > 0 ? (
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-8 md:gap-12">
              {cast.cast.slice(0, 12).map((person, index) => (
                <div className="grid sm:flex sm:items-center gap-y-3 gap-x-4">
                  <img className="rounded-lg w-20 h-20 object-cover object-center" src={person.profile_path ? `https://image.tmdb.org/t/p/w500/${person.profile_path}` : 'https://placehold.co/500x752?text=Cast'} alt={person.name} />

                  <div className="sm:flex sm:flex-col sm:h-full">
                    <div>
                      <h3 className="font-medium text-gray-800 dark:text-gray-200">
                        {person.name}
                      </h3>
                      <p className="mt-1 text-xs uppercase text-gray-500">
                        {person.character}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <NotAvailable />
          )}
        </div>
      </div>
    </div>
  </div>
</>
  );
};

export default Cast;
