import React, { useState, useEffect } from 'react';
import {useTranslation} from "react-i18next";

const Cards = ({ searchQuery }) => {
  const [movies, setMovies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const {t} = useTranslation("common");

  useEffect(() => {
    const fetchMovies = async () => {
      setIsLoading(true);
      setError(null);

      let url;

      const domain = window.location.hostname;
      let lng = 'en-US';

      if (domain === 'poscreditos.es') {
        lng = 'es-ES';
      }else{
        lng = 'en-US';
      }

      const apiUrl = process.env.REACT_APP_API_BASE_URL;
      if (!apiUrl) {
        throw new Error('API URL not found');
      }
      if (searchQuery) {
        url = `${apiUrl}/api/${lng}/search/${encodeURIComponent(searchQuery)}`;
        
      } else {
        url = `${apiUrl}/api/${lng}/nowplaying`;
      }

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Something went wrong');
        }
        const data = await response.json();
        setMovies(data.results);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMovies();
  }, [searchQuery]); // Dependency array includes searchQuery to refetch when it changes

  if (isLoading) {
    return (<div className="flex justify-center items-center h-[300px]"> {/* Adjust height as needed */}
      <div className="animate-spin inline-block w-8 h-8 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500" role="status" aria-label="loading">
        <span className="sr-only">Loading...</span>
      </div>
    </div>)
  }

  if (error) {
    return (
      <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div class="bg-red-50 border-s-4 border-red-500 p-4 dark:bg-red-800/30" role="alert">
          <div class="flex">
            <div class="flex-shrink-0">
              <span class="inline-flex justify-center items-center w-8 h-8 rounded-full border-4 border-red-100 bg-red-200 text-red-800 dark:border-red-900 dark:bg-red-800 dark:text-red-400">
                <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
              </span>
            </div>
            <div class="ms-3">
              <h3 class="text-gray-800 font-semibold dark:text-white">
              {t('cards.error.title')}
              </h3>
              <p class="text-sm text-gray-700 dark:text-gray-400">
              {t('cards.error.subtitle')}
              </p>
            </div>
          </div>
        </div>
      </div>)
  }

  return (
    <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
      <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 sm:gap-6">
        {movies.map(movie => (
          <a key={movie.id} className="group flex flex-col bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-slate-900 dark:border-gray-800" href={`movie/${movie.id}`}>
            <div className="p-4 md:p-5">
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <img className="h-[2.375rem] w-[2.375rem] object-cover object-center rounded-lg" src={movie.poster_path ? `https://image.tmdb.org/t/p/w500/${movie.poster_path}` : 'https://placehold.co/500x500?text=M'} alt={movie.title} />
                  <div className="ms-3">
                    <h3 className="group-hover:text-blue-600 font-semibold text-gray-800 dark:group-hover:text-gray-400 dark:text-gray-200">
                      {movie.title}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Cards;