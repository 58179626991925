import { useTranslation } from "react-i18next";
import React from 'react';
import NotAvailable from "./NotAvailable";

interface StatsProps {
    revenue: number;
    budget: number;
    runtime: number;
    vote_average: number;
    vote_count: number;
}

const Spoiler: React.FC<StatsProps> = ({ revenue, budget, runtime, vote_average, vote_count }) => {
    const { t } = useTranslation("common");
    return (
        <>
            <div className="group flex flex-col bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-slate-900 dark:border-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 mt-10">
                <div className="p-4 md:p-5">
                    <div className="flex">
                        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                            <div className="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
                                <h2 className="text-2xl font-bold md:text-4xl md:leading-tight dark:text-white">{t('stats.title')}</h2>
                                <p className="mt-1 text-gray-600 dark:text-gray-400">{t('stats.subtitle')}</p>
                            </div>
                            <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                                <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 sm:gap-12 lg:grid-cols-1 lg:gap-8">
                                    <div>
                                        <h4 className="text-lg sm:text-xl font-semibold text-gray-800 dark:text-gray-200">{t('stats.duration')}</h4>
                                        <p className="mt-2 sm:mt-3 text-4xl sm:text-5xl font-bold text-blue-600">{Math.floor(runtime / 60)} {Math.floor(runtime / 60) === 1 ? t('stats.hour') : t('stats.hours')}</p>
                                        <p className="mt-2 sm:mt-3 text-4xl sm:text-5xl font-bold text-blue-600">{runtime % 60} {t('stats.minutes')}</p>
                                    </div>
                                    <div>
                                        <h4 className="text-lg sm:text-xl font-semibold text-gray-800 dark:text-gray-200">{t('stats.budget')}</h4>
                                        {budget !== 0 ? (
                                            <>
                                                <p className="mt-2 sm:mt-3 text-4xl sm:text-5xl font-bold text-blue-600">{(budget / 1000000).toFixed(2)}M</p>
                                                <p className="mt-1 text-gray-500">{t('stats.budget.notice')}</p>
                                            </>
                                        ) : (
                                            <>
                                                <p className="mt-2 sm:mt-3 text-4xl sm:text-5xl font-bold text-blue-600">{t('message.notavailable')}</p>
                                            </>
                                        )}
                                    </div>
                                    <div>
                                        <h4 className="text-lg sm:text-xl font-semibold text-gray-800 dark:text-gray-200">{t('stats.vote')}</h4>
                                        <p className="mt-2 sm:mt-3 text-4xl sm:text-5xl font-bold text-blue-600">{vote_average.toFixed(2)}</p>
                                        <p className="mt-1 text-gray-500">{t('stats.vote.notice')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Spoiler;
