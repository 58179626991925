import React from 'react';
import { useTranslation } from "react-i18next";

interface AccuracyAlertProps {
  release_date: string;
}

const AccuracyAlert: React.FC<AccuracyAlertProps> = ({ release_date }) => {
  const { t } = useTranslation("common");
  const currentDate = new Date();
  const releaseDate = new Date(release_date);

  // Check if the release date is in the future
  const isFutureRelease = releaseDate > currentDate;

  return (
    <>
      <div className="bg-gray-50 border border-gray-200 text-sm text-gray-600 rounded-lg p-4 dark:bg-white/[.05] dark:border-white/10 dark:text-gray-400" role="alert">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="flex-shrink-0 h-4 w-4 mt-0.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10" /><path d="M12 16v-4" /><path d="M12 8h.01" /></svg>
          </div>
          <div className="flex-1 md:flex md:justify-between ms-2">
            {isFutureRelease ? (
              <p className="text-sm">
                {t('movie.accuracyalert.future_notice')}
              </p>
            ) : (
              <p className="text-sm">
                {t('movie.accuracyalert.notice')}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AccuracyAlert;
