import {useTranslation} from "react-i18next";

const Navbar = () => {
  const domain = window.location.hostname;
  const {t} = useTranslation("common");
  return (
    <header className="flex flex-wrap md:justify-start md:flex-nowrap z-50 w-full text-sm">
      <nav className="mt-6 relative max-w-[85rem] w-full bg-white border border-gray-200 rounded-[36px] mx-2 py-3 px-4 md:flex md:items-center md:justify-between md:py-0 md:px-6 lg:px-8 xl:mx-auto dark:bg-gray-800 dark:border-gray-700" aria-label="Global">
        <div className="flex items-center justify-between">
        <svg width="30" height="30" viewBox="0 -7.59 154.79 154.79" xmlns="http://www.w3.org/2000/svg">
                                <g id="movie_ticket" data-name="movie ticket" transform="translate(-517.238 -128.444)">
                                    <path id="Path_4483" data-name="Path 4483" d="M528.737,170.978l-10.13,14.781a7.885,7.885,0,0,0,2.04,10.929l17.983,12.326a5.554,5.554,0,0,1,9.159,6.278l74.993,51.4a7.887,7.887,0,0,0,10.931-2.041l10.1-14.733a13.752,13.752,0,0,1,15.548-22.685l11.3-16.487a7.887,7.887,0,0,0-2.04-10.931l-75.037-51.427a4.715,4.715,0,0,1-.315.578,5.569,5.569,0,0,1-9.186-6.3,4.772,4.772,0,0,1,.425-.5l-18.024-12.354a7.885,7.885,0,0,0-10.93,2.042l-11.333,16.536a13.754,13.754,0,0,1-15.482,22.587Zm51.7-13.137a5.569,5.569,0,1,1-7.743,1.446A5.572,5.572,0,0,1,580.434,157.841ZM569.043,174.46A5.569,5.569,0,1,1,561.3,175.9,5.565,5.565,0,0,1,569.043,174.46Zm-11.389,16.617a5.569,5.569,0,1,1-7.741,1.445A5.569,5.569,0,0,1,557.654,191.077Z" fill="#f39014" />
                                    <g id="Group_1918" data-name="Group 1918">
                                        <path id="Path_4484" data-name="Path 4484" d="M527.48,242.279V260.2a7.885,7.885,0,0,0,7.863,7.862h21.8a5.554,5.554,0,0,1,11.1,0h90.919a7.884,7.884,0,0,0,7.861-7.862V242.336a13.751,13.751,0,0,1,0-27.5V194.848a7.885,7.885,0,0,0-7.861-7.863H568.2a5.266,5.266,0,0,1,.066.656,5.569,5.569,0,1,1-11.137,0,5.458,5.458,0,0,1,.066-.656H535.343a7.886,7.886,0,0,0-7.863,7.863V214.9a13.754,13.754,0,0,1,0,27.383ZM562.7,202.218a5.569,5.569,0,1,1-5.569,5.568A5.568,5.568,0,0,1,562.7,202.218Zm0,20.146a5.569,5.569,0,1,1-5.569,5.568A5.568,5.568,0,0,1,562.7,222.364Zm0,20.145a5.569,5.569,0,1,1-5.569,5.569A5.568,5.568,0,0,1,562.7,242.509Z" fill="#fce367" />
                                        <path id="Path_4485" data-name="Path 4485" d="M527.48,242.279V260.2a7.885,7.885,0,0,0,7.863,7.862h21.8a5.559,5.559,0,0,1,5.552-5.4v-9.007a5.569,5.569,0,0,1,0-11.139V233.5a5.569,5.569,0,1,1,0-11.138v-9.008a5.569,5.569,0,1,1,0-11.138V193.21a5.571,5.571,0,0,1-5.569-5.569,5.458,5.458,0,0,1,.066-.656H535.343a7.886,7.886,0,0,0-7.863,7.863V214.9a13.754,13.754,0,0,1,0,27.383Z" fill="#f8c140" />
                                        <path id="Path_4486" data-name="Path 4486" d="M578.91,253.648a2.621,2.621,0,0,1,2.621-2.622h69.337a2.623,2.623,0,0,1,2.621,2.622h0a2.62,2.62,0,0,1-2.621,2.618H581.531a2.619,2.619,0,0,1-2.621-2.618Z" fill="#f39014" />
                                        <path id="Path_4487" data-name="Path 4487" d="M578.91,202.218a2.621,2.621,0,0,1,2.621-2.621h69.337a2.622,2.622,0,0,1,2.621,2.621h0a2.621,2.621,0,0,1-2.621,2.619H581.531a2.619,2.619,0,0,1-2.621-2.619Z" fill="#f39014" />
                                        <g id="Group_1917" data-name="Group 1917">
                                            <g id="Group_1916" data-name="Group 1916">
                                                <path id="Path_4488" data-name="Path 4488" d="M607.839,234.345a5.327,5.327,0,0,0-1.247-3.945l-5.154-5.395c-.873-.913-.566-1.836.682-2.043l7.359-1.225a5.314,5.314,0,0,0,3.366-2.407l3.539-6.567c.6-1.115,1.57-1.109,2.154.016l3.439,6.62a5.317,5.317,0,0,0,3.33,2.457l7.337,1.336c1.248.227,1.541,1.152.653,2.055l-5.235,5.316a5.325,5.325,0,0,0-1.308,3.924l1,7.394c.17,1.257-.62,1.82-1.753,1.254l-6.674-3.335a5.324,5.324,0,0,0-4.138-.03L608.466,243c-1.142.55-1.922-.026-1.735-1.28Z" fill="#f39014" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
          <a className="ml-2 flex-none text-xl font-semibold dark:text-white" href="/" aria-label="Brand">{t('app.title')}</a>
          <div className="md:hidden">
            <button type="button" className="hs-collapse-toggle w-8 h-8 flex justify-center items-center text-sm font-semibold rounded-full border border-gray-200 text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" data-hs-collapse="#navbar-collapse-with-animation" aria-controls="navbar-collapse-with-animation" aria-label="Toggle navigation">
              <svg className="hs-collapse-open:hidden flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="3" x2="21" y1="6" y2="6" /><line x1="3" x2="21" y1="12" y2="12" /><line x1="3" x2="21" y1="18" y2="18" /></svg>
              <svg className="hs-collapse-open:block hidden flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
            </button>
          </div>
        </div>
        <div id="navbar-collapse-with-animation" className="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow md:block">
          <div className="flex flex-col gap-y-4 gap-x-0 mt-5 md:flex-row md:items-center md:justify-end md:gap-y-0 md:gap-x-7 md:mt-0 md:ps-7">
            <a className="font-medium text-gray-500 hover:text-gray-400 md:py-6 dark:text-gray-400 dark:hover:text-gray-500" href="/">{t('navbar.home')}</a>
            {domain === "poscreditos.es" ? (
              <a className="font-medium text-gray-500 hover:text-gray-400 md:py-6 dark:text-gray-400 dark:hover:text-gray-500" href="https://haspostcredits.com">English</a>
            ) : (
              <a className="font-medium text-gray-500 hover:text-gray-400 md:py-6 dark:text-gray-400 dark:hover:text-gray-500" href="https://poscreditos.es">Español</a>
            )}
            <a className="flex items-center gap-x-2 font-medium text-gray-500 hover:text-blue-600 md:border-s md:border-gray-300 md:my-6 md:ps-6 dark:border-gray-700 dark:text-gray-400 dark:hover:text-blue-500" href="https://www.buymeacoffee.com/dabiz" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-person-hearts" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M11.5 1.246c.832-.855 2.913.642 0 2.566-2.913-1.924-.832-3.421 0-2.566M9 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0m-9 8c0 1 1 1 1 1h10s1 0 1-1-1-4-6-4-6 3-6 4m13.5-8.09c1.387-1.425 4.855 1.07 0 4.277-4.854-3.207-1.387-5.702 0-4.276ZM15 2.165c.555-.57 1.942.428 0 1.711-1.942-1.283-.555-2.281 0-1.71Z"/>
</svg>                {t('navbar.support')}
            </a>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
