import React from 'react';
import NotAvailable from './NotAvailable';

interface OverviewProps {
  overview: string;
  backdrop_path: string;
  movieTitle: string;
  production_companies: CompanyType[];
}

type CompanyType = {
    id: number;
    logo_path: string | null;
    name: string;
    origin_country: string;
};

const Spoiler: React.FC<OverviewProps> = ({ overview, backdrop_path, movieTitle, production_companies }) => {
  return (
    <>
<div className="relative overflow-hidden rounded-lg mt-10">
  <div className="max-w-[85rem] px-4 py-12 sm:px-6 lg:px-8 lg:py-16 mx-auto">
    <div aria-hidden="true" className="flex absolute start-0 -z-[1]">
      <div className="bg-blue-200 opacity-20 blur-3xl w-[1036px] h-[300px] dark:bg-blue-900 dark:opacity-20"></div>
    </div>

    <div className="lg:grid lg:grid-cols-6 lg:gap-8 lg:items-center">
      <div className="hidden lg:block lg:col-span-2">
        <img className="rounded-xl" src={backdrop_path ? `https://image.tmdb.org/t/p/w500/${backdrop_path}`: `https://placehold.co/500x281?text=No%20Art%20image%20available`} alt={`Art of ${movieTitle} movie`} />
      </div>

      <div className="lg:col-span-4">
        <blockquote>
        
        {production_companies.map((company, index) => (
            company.logo_path ? (
                <img
                    className='w-14 h-auto mb-4 inline mr-4'
                    src={`https://image.tmdb.org/t/p/w500/${company.logo_path}`}
                    alt={`Logo of ${company.name}`}
                />
            ) : null
        ))}
        
        <p className="text-xl font-medium text-gray-800 lg:text-2xl lg:leading-normal dark:text-gray-200">
          {overview ? overview : <NotAvailable />} 
          </p>
        </blockquote>
      </div>
    </div>
  </div>
</div>
    </>
  );
};

export default Spoiler;
