import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Spoiler from './Spoiler';
import ShareButtons from './ShareButtons';
import MovieGenre from './MovieGenre';
import Cast from './Cast';
import Overview from './Overview';
import Stats from './Stats';
import AccuracyAlert from './AccuracyAlert';
import { useTranslation } from "react-i18next";


type GenreType = {
    id: number;
    name: string;
};

type CollectionType = {
    id: number;
    name: string;
    poster_path: string;
    backdrop_path: string;
};

type CompanyType = {
    id: number;
    logo_path: string | null;
    name: string;
    origin_country: string;
};

type CountryType = {
    iso_3166_1: string;
    name: string;
};

type LanguageType = {
    english_name: string;
    iso_639_1: string;
    name: string;
};

type MovieType = {
    adult: boolean;
    backdrop_path: string;
    belongs_to_collection: CollectionType | null;
    budget: number;
    genres: GenreType[];
    homepage: string;
    id: number;
    imdb_id: string;
    original_language: string;
    original_title: string;
    overview: string;
    popularity: number;
    poster_path: string;
    production_companies: CompanyType[];
    production_countries: CountryType[];
    release_date: string;
    revenue: number;
    runtime: number;
    spoken_languages: LanguageType[];
    status: string;
    tagline: string;
    title: string;
    video: boolean;
    vote_average: number;
    vote_count: number;
};

type HpcDescriptionType = {
    Description: string;
    Language: string;
};

type HpcDataType = {
    ID: string;
    Descriptions: HpcDescriptionType[];
    SourceTitle: string;
    TmdbId: number;
};


const Movie = () => {
    const { id } = useParams<{ id: string }>();
    const [movie, setMovie] = useState<MovieType | null>(null);
    const [hpcData, setHpcData] = useState<HpcDataType | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [isNotFound, setIsNotFound] = useState<boolean>(false); // New state for handling 404
    const { t } = useTranslation("common");
    const domain = window.location.hostname;
    let lng = 'en-US';

    if (domain === 'poscreditos.es') {
        lng = 'es-ES';
    } else {
        lng = 'en-US';
    }

    useEffect(() => {
        const fetchMovie = async () => {
            setIsLoading(true);
            setError(null);

            try {
                const apiUrl = process.env.REACT_APP_API_BASE_URL;
                if (!apiUrl) {
                    throw new Error('API URL not found');
                }
                // Fetch from TMDb
                const tmdbUrl = `${apiUrl}/api/${lng}/movies/${id}`;
                const tmdbResponse = await fetch(tmdbUrl);
                if (!tmdbResponse.ok) {
                    throw new Error('Error fetching movie data');
                }
                const tmdbData = await tmdbResponse.json();
                setMovie(tmdbData);
                document.title = tmdbData.title + ` | ${t('app.title')}`;

                // Fetch from HPC API with API key header
                const hpcUrl = `${apiUrl}/api/hpcdata/${id}`; // Replace with your HPC API URL
                const hpcResponse = await fetch(hpcUrl);
                if (!hpcResponse.ok) {
                    if (hpcResponse.status === 404) {
                        setIsNotFound(true); // Set isNotFound to true if HPC API returns 404
                    } else if (!hpcResponse.ok) {
                        throw new Error('Error fetching HPC data');
                    }
                }
                const hpcData = await hpcResponse.json();
                setHpcData(hpcData);
            } catch (error: any) {
                if (error instanceof Error) {
                    setError(error.message);
                } else {
                    // Handle the case where error is not an instance of Error
                    setError('An unknown error occurred');
                }
            } finally {
                setIsLoading(false);
            }
        };

        fetchMovie();
    }, [id]); // Dependency array includes id to refetch when it changes

    if (isLoading) {
        return (<div className="flex justify-center items-center h-[300px]"> {/* Adjust height as needed */}
            <div className="animate-spin inline-block w-8 h-8 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500" role="status" aria-label="loading">
                <span className="sr-only">Loading...</span>
            </div>
        </div>)
    }

    if (isNotFound) {
        return (<div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <div className="grid md:grid-cols-2 gap-4 md:gap-8 xl:gap-20 md:items-center">
                <div>
                    {movie && (
                        <h1 className="block text-3xl font-bold text-gray-800 sm:text-4xl lg:text-6xl lg:leading-tight dark:text-white">{movie.title} <span className="text-red-600">{movie.status !== "Released" ? (
                            t('movie.title.notreleased')
                        ) : (
                            t('movie.title.nopostcredits')
                        )}</span>.</h1>
                    )}
                    <p className="mt-3 text-lg text-gray-800 dark:text-gray-400"> {movie && movie.status !== "Released" ?
                        t('movie.subtitle.notreleased') :
                        t('movie.subtitle.nopostcredits')}</p>
                    <div className="mt-7 grid gap-3 w-full">
                        {movie && (
                            (Date.now() < new Date(movie.release_date).getTime() ||
                                (Date.now() - new Date(movie.release_date).getTime()) / (1000 * 60 * 60 * 24) <= 3) && (
                                <AccuracyAlert release_date={movie.release_date ?? ""} />
                            )
                        )}
                    </div>
                    <div className="mt-6 lg:mt-10 grid grid-cols-2 gap-x-5">

                        <div className="py-5">
                            <p className="mt-3 text-sm text-gray-800 dark:text-gray-200">
                                <div className="inline-flex gap-1">
                                    {movie?.genres.slice(0, 4).map((genre, index) => (
                                        <MovieGenre key={index} genre={genre.name} />
                                    ))}
                                </div>
                                <ShareButtons movieTitle={movie?.title ?? ""} movieId={movie?.id ?? 119450} />
                            </p>
                        </div>
                    </div>
                </div>

                <div className="relative flex justify-center">
                    {movie && (
                        <img className="w-full max-w-[450px] rounded-md" src={`https://image.tmdb.org/t/p/original/${movie.poster_path}`} alt={`Poster of the ${movie.title} movie`} />
                    )}
                </div>
            </div>
            <Overview overview={movie?.overview ?? ""} backdrop_path={movie?.backdrop_path ?? ""} movieTitle={movie?.title ?? ""} production_companies={movie?.production_companies ?? []} />
            <div className="grid md:grid-cols-2 gap-4 md:gap-8 xl:gap-20 md:items-stretch">
                <Stats revenue={movie?.revenue ?? 0} runtime={movie?.runtime ?? 0} budget={movie?.budget ?? 0} vote_average={movie?.vote_average ?? 0} vote_count={movie?.vote_count ?? 0} />
                <Cast id={movie?.id ?? 0} />
            </div>
        </div>)
    }

    if (error) {
        return (
            <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                <div className="bg-red-50 border-s-4 border-red-500 p-4 dark:bg-red-800/30" role="alert">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <span className="inline-flex justify-center items-center w-8 h-8 rounded-full border-4 border-red-100 bg-red-200 text-red-800 dark:border-red-900 dark:bg-red-800 dark:text-red-400">
                                <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
                            </span>
                        </div>
                        <div className="ms-3">
                            <h3 className="text-gray-800 font-semibold dark:text-white">
                                {t('movie.error.title')}
                            </h3>
                            <p className="text-sm text-gray-700 dark:text-gray-400">
                                {t('movie.error.subtitle')} ({error}).
                            </p>
                        </div>
                    </div>
                </div>
            </div>)
    }

    return (
        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <div className="grid md:grid-cols-2 gap-4 md:gap-8 xl:gap-20 md:items-center">
                <div>
                    {movie && (
                        <h1 className="block text-3xl font-bold text-gray-800 sm:text-4xl lg:text-6xl lg:leading-tight dark:text-white">{movie.title} <span className="text-green-600">{t('movie.title.haspostcredits')}</span>.</h1>
                    )}
                    <p className="mt-3 text-lg text-gray-800 dark:text-gray-400">{t('movie.subtitle.haspostcredits')}</p>

                    <div className="mt-7 grid gap-3 w-full">
                        {hpcData && (
                            <Spoiler description={domain === 'poscreditos.es' ? hpcData.Descriptions[1].Description : hpcData.Descriptions[0].Description} />
                        )}
                        {movie && (
                            (Date.now() < new Date(movie.release_date).getTime() ||
                                (Date.now() - new Date(movie.release_date).getTime()) / (1000 * 60 * 60 * 24) <= 3) && (
                                <AccuracyAlert release_date={movie.release_date ?? ""} />
                            )
                        )}
                    </div>
                    <div className="mt-6 lg:mt-10 grid grid-cols-2 gap-x-5">
                        <div className="py-5">
                            <p className="mt-3 text-sm text-gray-800 dark:text-gray-200">
                                <div className="inline-flex gap-1">
                                    {movie?.genres.slice(0, 4).map((genre, index) => (
                                        <MovieGenre key={index} genre={genre.name} />
                                    ))}
                                </div>
                                <ShareButtons movieTitle={movie?.title ?? ""} movieId={movie?.id ?? 119450} />
                            </p>
                        </div>
                    </div>
                </div>

                <div className="relative flex justify-center">
                    {movie && (
                        <img className="w-full max-w-[450px] rounded-md" src={`https://image.tmdb.org/t/p/original/${movie.poster_path}`} alt={`Poster of the ${movie.title} movie`} />
                    )}
                </div>
            </div>
            <Overview overview={movie?.overview ?? ""} backdrop_path={movie?.backdrop_path ?? ""} movieTitle={movie?.title ?? ""} production_companies={movie?.production_companies ?? []} />
            <div className="grid md:grid-cols-2 gap-4 md:gap-8 xl:gap-20 md:items-stretch">
                <Stats revenue={movie?.revenue ?? 0} runtime={movie?.runtime ?? 0} budget={movie?.budget ?? 0} vote_average={movie?.vote_average ?? 0} vote_count={movie?.vote_count ?? 0} />
                <Cast id={movie?.id ?? 0} />
            </div>
        </div>
    );
};

export default Movie;


