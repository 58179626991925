import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import common_es from "./translations/es-ES/common.json";
import common_en from "./translations/en-US/common.json";

const domain = window.location.hostname;
let lng = 'en-US';

if (domain === 'poscreditos.es') {
  lng = 'es-ES';
}else{
  lng = 'en-US';
}

i18next.init({
  interpolation: { escapeValue: false },
  lng: lng,                              
  resources: {
    en: {
      common: common_en               
    },
    es: {
      common: common_es
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <I18nextProvider i18n={i18next}>
      <BrowserRouter basename="/">
        <App />
      </BrowserRouter>
      </I18nextProvider>
  </React.StrictMode>
);
reportWebVitals();
