import React, { useState } from 'react';
import {useTranslation} from "react-i18next";

import Hero from './Hero';
import Cards from './Cards';

const HomePage = () => {
    const {t} = useTranslation("common");
    document.title = t("app.title");
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (query) => {
        setSearchQuery(query);
    };
    return (
        <div>
            <Hero onSearchChange={handleSearchChange} />
            <Cards searchQuery={searchQuery} />
        </div>
    );
};

export default HomePage;
